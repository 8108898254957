import { UserEditing } from 'components';
import { getBooking } from 'data-graphql';
import React from 'react';

export default class OrgEdits extends React.Component {
  constructor() {
    super();
    this.state = {booking: null};
  }

  getBooking = async () => {
    const VARS = { id: this.props.match.params.bookingId };
    const REQ_OBJECT = `{
      id
      show {
        name
        edits_doc_url
        cue_doc_url
      }
      opening
      expiration
      rehearsal_active_date
      production_active_date
      rate
      is_production_access
      edits_status
      rate_status
      notes
      notes_color
      rate
      state
      is_downloaded
      rehearsal_code
      rehearsal_edit_code
      authorizations {
        device {
          description
          id
        }
        user {
          id
          username
        }
        is_edit_rehearsal
      }
    }`;

    const RESULT = await getBooking(VARS, REQ_OBJECT);
    this.setState({
      booking: RESULT.data.getBooking
    });
  };

  componentDidMount() {
    this.getBooking();
  }

  render() {
    if (!this.state.booking) return <div>Loading...</div>;
    return <div style={{padding: '40px'}}>
      <UserEditing booking={this.state.booking} id={this.props.match.params.id} />
    </div>
  }
}
